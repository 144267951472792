import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const ClevelandClinicLandingPage = () => (
  <PhysicianLandingPage
    physician="Sarah Vij, MD"
    institution="Cleveland Clinic"
    referralCode="CLEVELANDCLINIC"
    physicianURL="https://my.clevelandclinic.org/staff/22270-sarah-vij"
  />
)

export default ClevelandClinicLandingPage
